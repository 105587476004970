
import { createCanvas, getCanvasShareLink, getShare } from "@/api";
import {Options, Vue} from "vue-class-component";
import {CanvasDTO} from "@/types/api/InfiniteData";
import {ShareDTO} from "@/types/api/Common";
import CreationShareData from "@/types/api/CreationData";

@Options<CanvasShare>({
  name: "CanvasShare"
})
export default class CanvasShare extends Vue{
  shareKey='';
  canvasName=""
  canvasDTO:CanvasDTO|null=null;
  shareLink:string="";
  shareDTO:ShareDTO|null=null
  mounted(){
    this.shareKey=this.$route.query.shareKey as  string
    if(this.shareKey!=null){
      this.getShare()
    }
  }
  createCanvas(){
    createCanvas(this.canvasName).then(res=>{
      this.canvasDTO=res.data.data;
      console.log(this.canvasDTO)
    })
  }

  getCanvasShareLink(){
    if(this.canvasDTO!=null) {
      getCanvasShareLink(this.canvasDTO.id).then(res => {
         this.shareLink=res.data.data;
      })
    }
  }

  getShare(){
    if(this.shareKey!=null){
      getShare(this.shareKey).then(res=>{
        this.shareDTO=res.data.data;
        console.log(this.shareDTO)
        if(this.shareDTO?.shareType==="canvas-share") {
          this.$router.push({
            path: this.shareDTO?.url,
            query: {
              id: this.shareDTO.data
            }
          })
        }
        else if(this.shareDTO?.shareType==='creation-share'){
          const data:CreationShareData = this.shareDTO.data;
          //这里拿到了data 你可以存localstorage然后跳转到特定的页面再显示出来并且把localstorage给删除了
        }
      })
    }

  }
  copyText(text:string) {
    var input = document.createElement("input"); // 创建input对象
    input.value = text; // 设置复制内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
  }


}
